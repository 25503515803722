:root {
  --field-border: rgba(0, 0, 0, 0.12); // material default for form fields
}

.force-center.mat-form-field {
  .mat-form-field-infix {
    padding: .75em 0 1.25em 0;
  }
}

.no-spacing.mat-form-field {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;
  }
}

mat-form-field {
  width: 100%;
}
