.page-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;

  margin-bottom: 16px;

  .back {
    display: flex;
    align-items: center;

    background: transparent;
    border: 0;
    height: 32px;

    mat-icon {
      transform: scale(1.5);
    }
  }

  h1 {
    margin-bottom: unset;
  }
}
