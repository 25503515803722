:root {
  --page-create-button-inset: 16px;
}

.page-create-button {
  position: fixed;
  right: var(--page-create-button-inset);
  bottom: var(--page-create-button-inset);

  &.mat-fab {
    position: fixed;
  }
}
