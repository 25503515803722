/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
  50: #e4f1f4,
  100: #bbdce5,
  200: #8ec5d3,
  300: #60adc1,
  400: #3e9cb4,
  500: #1c8aa7,
  600: #19829f,
  700: #147796,
  800: #116d8c,
  900: #095a7c,
  A100: #ace3ff,
  A200: #79d3ff,
  A400: #46c2ff,
  A700: #2cb9ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-secondary: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #ffffff,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
