@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}

.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}

.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color:rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color:#673ab7;
  --mat-option-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color:rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color:#ffd740;
  --mat-option-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color:rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color:#f44336;
  --mat-option-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color:rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color:rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color:#673ab7;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#673ab7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color:#ffd740;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#ffd740;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color:#ffd740;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#ffd740;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color:#f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color:#fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color:#b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color:#b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color:#f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color:#b0b0b0;
}

html {
  --mat-app-background-color:#fafafa;
  --mat-app-text-color:rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font:Roboto, sans-serif;
  --mat-option-label-text-line-height:24px;
  --mat-option-label-text-size:16px;
  --mat-option-label-text-tracking:0.03125em;
  --mat-option-label-text-weight:400;
}

html {
  --mat-optgroup-label-text-font:Roboto, sans-serif;
  --mat-optgroup-label-text-line-height:24px;
  --mat-optgroup-label-text-size:16px;
  --mat-optgroup-label-text-tracking:0.03125em;
  --mat-optgroup-label-text-weight:400;
}

html {
  --mdc-elevated-card-container-shape:4px;
  --mdc-outlined-card-container-shape:4px;
  --mdc-outlined-card-outline-width:1px;
}

html {
  --mdc-elevated-card-container-color:white;
  --mdc-elevated-card-container-elevation:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color:white;
  --mdc-outlined-card-outline-color:rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color:rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font:Roboto, sans-serif;
  --mat-card-title-text-line-height:32px;
  --mat-card-title-text-size:20px;
  --mat-card-title-text-tracking:0.0125em;
  --mat-card-title-text-weight:500;
  --mat-card-subtitle-text-font:Roboto, sans-serif;
  --mat-card-subtitle-text-line-height:22px;
  --mat-card-subtitle-text-size:14px;
  --mat-card-subtitle-text-tracking:0.0071428571em;
  --mat-card-subtitle-text-weight:500;
}

html {
  --mdc-linear-progress-active-indicator-height:4px;
  --mdc-linear-progress-track-height:4px;
  --mdc-linear-progress-track-shape:0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color:#673ab7;
  --mdc-linear-progress-track-color:rgba(103, 58, 183, 0.25);
}

.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color:#ffd740;
  --mdc-linear-progress-track-color:rgba(255, 215, 64, 0.25);
}

.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color:#f44336;
  --mdc-linear-progress-track-color:rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape:4px;
  --mdc-plain-tooltip-supporting-text-line-height:16px;
}

html {
  --mdc-plain-tooltip-container-color:#616161;
  --mdc-plain-tooltip-supporting-text-color:#fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font:Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size:12px;
  --mdc-plain-tooltip-supporting-text-weight:400;
  --mdc-plain-tooltip-supporting-text-tracking:0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height:1px;
  --mdc-filled-text-field-focus-active-indicator-height:2px;
  --mdc-filled-text-field-container-shape:4px;
  --mdc-outlined-text-field-outline-width:1px;
  --mdc-outlined-text-field-focus-outline-width:2px;
  --mdc-outlined-text-field-container-shape:4px;
}

html {
  --mdc-filled-text-field-caret-color:#673ab7;
  --mdc-filled-text-field-focus-active-indicator-color:#673ab7;
  --mdc-filled-text-field-focus-label-text-color:rgba(103, 58, 183, 0.87);
  --mdc-filled-text-field-container-color:whitesmoke;
  --mdc-filled-text-field-disabled-container-color:#fafafa;
  --mdc-filled-text-field-label-text-color:rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color:rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color:rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color:rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color:rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color:#f44336;
  --mdc-filled-text-field-error-focus-label-text-color:#f44336;
  --mdc-filled-text-field-error-label-text-color:#f44336;
  --mdc-filled-text-field-error-caret-color:#f44336;
  --mdc-filled-text-field-active-indicator-color:rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color:rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color:rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color:#f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color:#f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color:#f44336;
  --mdc-outlined-text-field-caret-color:#673ab7;
  --mdc-outlined-text-field-focus-outline-color:#673ab7;
  --mdc-outlined-text-field-focus-label-text-color:rgba(103, 58, 183, 0.87);
  --mdc-outlined-text-field-label-text-color:rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color:rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color:rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color:rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color:rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color:#f44336;
  --mdc-outlined-text-field-error-focus-label-text-color:#f44336;
  --mdc-outlined-text-field-error-label-text-color:#f44336;
  --mdc-outlined-text-field-error-hover-label-text-color:#f44336;
  --mdc-outlined-text-field-outline-color:rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color:rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color:rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color:#f44336;
  --mdc-outlined-text-field-error-hover-outline-color:#f44336;
  --mdc-outlined-text-field-error-outline-color:#f44336;
  --mat-form-field-focus-select-arrow-color:rgba(103, 58, 183, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color:rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color:rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color:#f44336;
  --mat-form-field-select-option-text-color:inherit;
  --mat-form-field-select-disabled-option-text-color:GrayText;
  --mat-form-field-leading-icon-color:unset;
  --mat-form-field-disabled-leading-icon-color:unset;
  --mat-form-field-trailing-icon-color:unset;
  --mat-form-field-disabled-trailing-icon-color:unset;
  --mat-form-field-error-focus-trailing-icon-color:unset;
  --mat-form-field-error-hover-trailing-icon-color:unset;
  --mat-form-field-error-trailing-icon-color:unset;
  --mat-form-field-enabled-select-arrow-color:rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color:rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity:0.04;
  --mat-form-field-focus-state-layer-opacity:0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color:#ffd740;
  --mdc-filled-text-field-focus-active-indicator-color:#ffd740;
  --mdc-filled-text-field-focus-label-text-color:rgba(255, 215, 64, 0.87);
  --mdc-outlined-text-field-caret-color:#ffd740;
  --mdc-outlined-text-field-focus-outline-color:#ffd740;
  --mdc-outlined-text-field-focus-label-text-color:rgba(255, 215, 64, 0.87);
  --mat-form-field-focus-select-arrow-color:rgba(255, 215, 64, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color:#f44336;
  --mdc-filled-text-field-focus-active-indicator-color:#f44336;
  --mdc-filled-text-field-focus-label-text-color:rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color:#f44336;
  --mdc-outlined-text-field-focus-outline-color:#f44336;
  --mdc-outlined-text-field-focus-label-text-color:rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color:rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height:56px;
  --mat-form-field-filled-label-display:block;
  --mat-form-field-container-vertical-padding:16px;
  --mat-form-field-filled-with-label-container-padding-top:24px;
  --mat-form-field-filled-with-label-container-padding-bottom:8px;
}

html {
  --mdc-filled-text-field-label-text-font:Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size:16px;
  --mdc-filled-text-field-label-text-tracking:0.03125em;
  --mdc-filled-text-field-label-text-weight:400;
  --mdc-outlined-text-field-label-text-font:Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size:16px;
  --mdc-outlined-text-field-label-text-tracking:0.03125em;
  --mdc-outlined-text-field-label-text-weight:400;
  --mat-form-field-container-text-font:Roboto, sans-serif;
  --mat-form-field-container-text-line-height:24px;
  --mat-form-field-container-text-size:16px;
  --mat-form-field-container-text-tracking:0.03125em;
  --mat-form-field-container-text-weight:400;
  --mat-form-field-outlined-label-text-populated-size:16px;
  --mat-form-field-subscript-text-font:Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height:20px;
  --mat-form-field-subscript-text-size:12px;
  --mat-form-field-subscript-text-tracking:0.0333333333em;
  --mat-form-field-subscript-text-weight:400;
}

html {
  --mat-select-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color:white;
  --mat-select-enabled-trigger-text-color:rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color:rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color:rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color:rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color:rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color:rgba(103, 58, 183, 0.87);
  --mat-select-invalid-arrow-color:rgba(244, 67, 54, 0.87);
}

html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color:white;
  --mat-select-enabled-trigger-text-color:rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color:rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color:rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color:rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color:rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color:rgba(255, 215, 64, 0.87);
  --mat-select-invalid-arrow-color:rgba(244, 67, 54, 0.87);
}

html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color:white;
  --mat-select-enabled-trigger-text-color:rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color:rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color:rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color:rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color:rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color:rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color:rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform:translateY(-8px);
}

html {
  --mat-select-trigger-text-font:Roboto, sans-serif;
  --mat-select-trigger-text-line-height:24px;
  --mat-select-trigger-text-size:16px;
  --mat-select-trigger-text-tracking:0.03125em;
  --mat-select-trigger-text-weight:400;
}

html {
  --mat-autocomplete-container-shape:4px;
  --mat-autocomplete-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color:white;
}

html {
  --mdc-dialog-container-elevation-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color:#000;
  --mdc-dialog-container-shape:4px;
  --mat-dialog-container-max-width:80vw;
  --mat-dialog-container-small-max-width:80vw;
  --mat-dialog-container-min-width:0;
  --mat-dialog-actions-alignment:start;
  --mat-dialog-actions-padding:8px;
  --mat-dialog-content-padding:20px 24px;
  --mat-dialog-with-actions-content-padding:20px 24px;
  --mat-dialog-headline-padding:0 24px 9px;
}

html {
  --mdc-dialog-container-color:white;
  --mdc-dialog-subhead-color:rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color:rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font:Roboto, sans-serif;
  --mdc-dialog-subhead-line-height:32px;
  --mdc-dialog-subhead-size:20px;
  --mdc-dialog-subhead-weight:500;
  --mdc-dialog-subhead-tracking:0.0125em;
  --mdc-dialog-supporting-text-font:Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height:24px;
  --mdc-dialog-supporting-text-size:16px;
  --mdc-dialog-supporting-text-weight:400;
  --mdc-dialog-supporting-text-tracking:0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family:rounded;
  --mdc-chip-container-shape-radius:16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family:rounded;
  --mdc-chip-with-avatar-avatar-shape-radius:14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size:28px;
  --mdc-chip-with-icon-icon-size:18px;
  --mdc-chip-outline-width:0;
  --mdc-chip-outline-color:transparent;
  --mdc-chip-disabled-outline-color:transparent;
  --mdc-chip-focus-outline-color:transparent;
  --mdc-chip-hover-state-layer-opacity:0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity:1;
  --mdc-chip-flat-selected-outline-width:0;
  --mdc-chip-selected-hover-state-layer-opacity:0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity:1;
  --mdc-chip-with-icon-disabled-icon-opacity:1;
  --mat-chip-disabled-container-opacity:0.4;
  --mat-chip-trailing-action-opacity:0.54;
  --mat-chip-trailing-action-focus-opacity:1;
  --mat-chip-trailing-action-state-layer-color:transparent;
  --mat-chip-selected-trailing-action-state-layer-color:transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity:0;
  --mat-chip-trailing-action-focus-state-layer-opacity:0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color:#212121;
  --mdc-chip-elevated-container-color:#e0e0e0;
  --mdc-chip-elevated-selected-container-color:#e0e0e0;
  --mdc-chip-elevated-disabled-container-color:#e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color:#e0e0e0;
  --mdc-chip-focus-state-layer-color:black;
  --mdc-chip-hover-state-layer-color:black;
  --mdc-chip-selected-hover-state-layer-color:black;
  --mdc-chip-focus-state-layer-opacity:0.12;
  --mdc-chip-selected-focus-state-layer-color:black;
  --mdc-chip-selected-focus-state-layer-opacity:0.12;
  --mdc-chip-label-text-color:#212121;
  --mdc-chip-selected-label-text-color:#212121;
  --mdc-chip-with-icon-icon-color:#212121;
  --mdc-chip-with-icon-disabled-icon-color:#212121;
  --mdc-chip-with-icon-selected-icon-color:#212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:#212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color:#212121;
  --mat-chip-selected-disabled-trailing-icon-color:#212121;
  --mat-chip-selected-trailing-icon-color:#212121;
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color:white;
  --mdc-chip-elevated-container-color:#673ab7;
  --mdc-chip-elevated-selected-container-color:#673ab7;
  --mdc-chip-elevated-disabled-container-color:#673ab7;
  --mdc-chip-flat-disabled-selected-container-color:#673ab7;
  --mdc-chip-focus-state-layer-color:black;
  --mdc-chip-hover-state-layer-color:black;
  --mdc-chip-selected-hover-state-layer-color:black;
  --mdc-chip-focus-state-layer-opacity:0.12;
  --mdc-chip-selected-focus-state-layer-color:black;
  --mdc-chip-selected-focus-state-layer-opacity:0.12;
  --mdc-chip-label-text-color:white;
  --mdc-chip-selected-label-text-color:white;
  --mdc-chip-with-icon-icon-color:white;
  --mdc-chip-with-icon-disabled-icon-color:white;
  --mdc-chip-with-icon-selected-icon-color:white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:white;
  --mdc-chip-with-trailing-icon-trailing-icon-color:white;
  --mat-chip-selected-disabled-trailing-icon-color:white;
  --mat-chip-selected-trailing-icon-color:white;
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color:#ffd740;
  --mdc-chip-elevated-selected-container-color:#ffd740;
  --mdc-chip-elevated-disabled-container-color:#ffd740;
  --mdc-chip-flat-disabled-selected-container-color:#ffd740;
  --mdc-chip-focus-state-layer-color:black;
  --mdc-chip-hover-state-layer-color:black;
  --mdc-chip-selected-hover-state-layer-color:black;
  --mdc-chip-focus-state-layer-opacity:0.12;
  --mdc-chip-selected-focus-state-layer-color:black;
  --mdc-chip-selected-focus-state-layer-opacity:0.12;
  --mdc-chip-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color:rgba(0, 0, 0, 0.87);
  --mat-chip-selected-disabled-trailing-icon-color:rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color:rgba(0, 0, 0, 0.87);
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color:white;
  --mdc-chip-elevated-container-color:#f44336;
  --mdc-chip-elevated-selected-container-color:#f44336;
  --mdc-chip-elevated-disabled-container-color:#f44336;
  --mdc-chip-flat-disabled-selected-container-color:#f44336;
  --mdc-chip-focus-state-layer-color:black;
  --mdc-chip-hover-state-layer-color:black;
  --mdc-chip-selected-hover-state-layer-color:black;
  --mdc-chip-focus-state-layer-opacity:0.12;
  --mdc-chip-selected-focus-state-layer-color:black;
  --mdc-chip-selected-focus-state-layer-opacity:0.12;
  --mdc-chip-label-text-color:white;
  --mdc-chip-selected-label-text-color:white;
  --mdc-chip-with-icon-icon-color:white;
  --mdc-chip-with-icon-disabled-icon-color:white;
  --mdc-chip-with-icon-selected-icon-color:white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color:white;
  --mdc-chip-with-trailing-icon-trailing-icon-color:white;
  --mat-chip-selected-disabled-trailing-icon-color:white;
  --mat-chip-selected-trailing-icon-color:white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height:32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font:Roboto, sans-serif;
  --mdc-chip-label-text-line-height:20px;
  --mdc-chip-label-text-size:14px;
  --mdc-chip-label-text-tracking:0.0178571429em;
  --mdc-chip-label-text-weight:400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity:0.38;
  --mdc-switch-disabled-track-opacity:0.12;
  --mdc-switch-disabled-unselected-icon-opacity:0.38;
  --mdc-switch-handle-height:20px;
  --mdc-switch-handle-shape:10px;
  --mdc-switch-handle-width:20px;
  --mdc-switch-selected-icon-size:18px;
  --mdc-switch-track-height:14px;
  --mdc-switch-track-shape:7px;
  --mdc-switch-track-width:36px;
  --mdc-switch-unselected-icon-size:18px;
  --mdc-switch-selected-focus-state-layer-opacity:0.12;
  --mdc-switch-selected-hover-state-layer-opacity:0.04;
  --mdc-switch-selected-pressed-state-layer-opacity:0.1;
  --mdc-switch-unselected-focus-state-layer-opacity:0.12;
  --mdc-switch-unselected-hover-state-layer-opacity:0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity:0.1;
  --mat-switch-disabled-selected-handle-opacity:0.38;
  --mat-switch-disabled-unselected-handle-opacity:0.38;
  --mat-switch-unselected-handle-size:20px;
  --mat-switch-selected-handle-size:20px;
  --mat-switch-pressed-handle-size:20px;
  --mat-switch-with-icon-handle-size:20px;
  --mat-switch-selected-handle-horizontal-margin:0;
  --mat-switch-selected-with-icon-handle-horizontal-margin:0;
  --mat-switch-selected-pressed-handle-horizontal-margin:0;
  --mat-switch-unselected-handle-horizontal-margin:0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin:0;
  --mat-switch-unselected-pressed-handle-horizontal-margin:0;
  --mat-switch-visible-track-opacity:1;
  --mat-switch-hidden-track-opacity:1;
  --mat-switch-visible-track-transition:transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition:transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width:1px;
  --mat-switch-track-outline-color:transparent;
  --mat-switch-selected-track-outline-width:1px;
  --mat-switch-disabled-unselected-track-outline-width:1px;
  --mat-switch-disabled-unselected-track-outline-color:transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color:#5e35b1;
  --mdc-switch-selected-handle-color:#5e35b1;
  --mdc-switch-selected-hover-state-layer-color:#5e35b1;
  --mdc-switch-selected-pressed-state-layer-color:#5e35b1;
  --mdc-switch-selected-focus-handle-color:#311b92;
  --mdc-switch-selected-hover-handle-color:#311b92;
  --mdc-switch-selected-pressed-handle-color:#311b92;
  --mdc-switch-selected-focus-track-color:#9575cd;
  --mdc-switch-selected-hover-track-color:#9575cd;
  --mdc-switch-selected-pressed-track-color:#9575cd;
  --mdc-switch-selected-track-color:#9575cd;
  --mdc-switch-disabled-selected-handle-color:#424242;
  --mdc-switch-disabled-selected-icon-color:#fff;
  --mdc-switch-disabled-selected-track-color:#424242;
  --mdc-switch-disabled-unselected-handle-color:#424242;
  --mdc-switch-disabled-unselected-icon-color:#fff;
  --mdc-switch-disabled-unselected-track-color:#424242;
  --mdc-switch-handle-surface-color:var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color:black;
  --mdc-switch-disabled-handle-elevation-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color:#fff;
  --mdc-switch-unselected-focus-handle-color:#212121;
  --mdc-switch-unselected-focus-state-layer-color:#424242;
  --mdc-switch-unselected-focus-track-color:#e0e0e0;
  --mdc-switch-unselected-handle-color:#616161;
  --mdc-switch-unselected-hover-handle-color:#212121;
  --mdc-switch-unselected-hover-state-layer-color:#424242;
  --mdc-switch-unselected-hover-track-color:#e0e0e0;
  --mdc-switch-unselected-icon-color:#fff;
  --mdc-switch-unselected-pressed-handle-color:#212121;
  --mdc-switch-unselected-pressed-state-layer-color:#424242;
  --mdc-switch-unselected-pressed-track-color:#e0e0e0;
  --mdc-switch-unselected-track-color:#e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color:rgba(0, 0, 0, 0.87);
}

html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color:#ffb300;
  --mdc-switch-selected-handle-color:#ffb300;
  --mdc-switch-selected-hover-state-layer-color:#ffb300;
  --mdc-switch-selected-pressed-state-layer-color:#ffb300;
  --mdc-switch-selected-focus-handle-color:#ff6f00;
  --mdc-switch-selected-hover-handle-color:#ff6f00;
  --mdc-switch-selected-pressed-handle-color:#ff6f00;
  --mdc-switch-selected-focus-track-color:#ffd54f;
  --mdc-switch-selected-hover-track-color:#ffd54f;
  --mdc-switch-selected-pressed-track-color:#ffd54f;
  --mdc-switch-selected-track-color:#ffd54f;
}

html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color:#e53935;
  --mdc-switch-selected-handle-color:#e53935;
  --mdc-switch-selected-hover-state-layer-color:#e53935;
  --mdc-switch-selected-pressed-state-layer-color:#e53935;
  --mdc-switch-selected-focus-handle-color:#b71c1c;
  --mdc-switch-selected-hover-handle-color:#b71c1c;
  --mdc-switch-selected-pressed-handle-color:#b71c1c;
  --mdc-switch-selected-focus-track-color:#e57373;
  --mdc-switch-selected-hover-track-color:#e57373;
  --mdc-switch-selected-pressed-track-color:#e57373;
  --mdc-switch-selected-track-color:#e57373;
}

html {
  --mdc-switch-state-layer-size:40px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font:Roboto, sans-serif;
  --mdc-form-field-label-text-line-height:20px;
  --mdc-form-field-label-text-size:14px;
  --mdc-form-field-label-text-tracking:0.0178571429em;
  --mdc-form-field-label-text-weight:400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity:0.38;
  --mdc-radio-disabled-unselected-icon-opacity:0.38;
  --mdc-radio-state-layer-size:40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color:rgba(0, 0, 0, 0.87);
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#673ab7;
  --mdc-radio-selected-hover-icon-color:#673ab7;
  --mdc-radio-selected-icon-color:#673ab7;
  --mdc-radio-selected-pressed-icon-color:#673ab7;
  --mat-radio-ripple-color:black;
  --mat-radio-checked-ripple-color:#673ab7;
  --mat-radio-disabled-label-color:rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#ffd740;
  --mdc-radio-selected-hover-icon-color:#ffd740;
  --mdc-radio-selected-icon-color:#ffd740;
  --mdc-radio-selected-pressed-icon-color:#ffd740;
  --mat-radio-ripple-color:black;
  --mat-radio-checked-ripple-color:#ffd740;
  --mat-radio-disabled-label-color:rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#f44336;
  --mdc-radio-selected-hover-icon-color:#f44336;
  --mdc-radio-selected-icon-color:#f44336;
  --mdc-radio-selected-pressed-icon-color:#f44336;
  --mat-radio-ripple-color:black;
  --mat-radio-checked-ripple-color:#f44336;
  --mat-radio-disabled-label-color:rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size:40px;
  --mat-radio-touch-target-display:block;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font:Roboto, sans-serif;
  --mdc-form-field-label-text-line-height:20px;
  --mdc-form-field-label-text-size:14px;
  --mdc-form-field-label-text-tracking:0.0178571429em;
  --mdc-form-field-label-text-weight:400;
}

html {
  --mat-slider-value-indicator-width:auto;
  --mat-slider-value-indicator-height:32px;
  --mat-slider-value-indicator-caret-display:block;
  --mat-slider-value-indicator-border-radius:4px;
  --mat-slider-value-indicator-padding:0 12px;
  --mat-slider-value-indicator-text-transform:none;
  --mat-slider-value-indicator-container-transform:translateX(-50%);
  --mdc-slider-active-track-height:6px;
  --mdc-slider-active-track-shape:9999px;
  --mdc-slider-handle-height:20px;
  --mdc-slider-handle-shape:50%;
  --mdc-slider-handle-width:20px;
  --mdc-slider-inactive-track-height:4px;
  --mdc-slider-inactive-track-shape:9999px;
  --mdc-slider-with-overlap-handle-outline-width:1px;
  --mdc-slider-with-tick-marks-active-container-opacity:0.6;
  --mdc-slider-with-tick-marks-container-shape:50%;
  --mdc-slider-with-tick-marks-container-size:2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity:0.6;
}

html {
  --mdc-slider-handle-color:#673ab7;
  --mdc-slider-focus-handle-color:#673ab7;
  --mdc-slider-hover-handle-color:#673ab7;
  --mdc-slider-active-track-color:#673ab7;
  --mdc-slider-inactive-track-color:#673ab7;
  --mdc-slider-with-tick-marks-inactive-container-color:#673ab7;
  --mdc-slider-with-tick-marks-active-container-color:white;
  --mdc-slider-disabled-active-track-color:#000;
  --mdc-slider-disabled-handle-color:#000;
  --mdc-slider-disabled-inactive-track-color:#000;
  --mdc-slider-label-container-color:#000;
  --mdc-slider-label-label-text-color:#fff;
  --mdc-slider-with-overlap-handle-outline-color:#fff;
  --mdc-slider-with-tick-marks-disabled-container-color:#000;
  --mdc-slider-handle-elevation:0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color:#673ab7;
  --mat-slider-hover-state-layer-color:rgba(103, 58, 183, 0.05);
  --mat-slider-focus-state-layer-color:rgba(103, 58, 183, 0.2);
  --mat-slider-value-indicator-opacity:0.6;
}

html .mat-accent {
  --mat-slider-ripple-color:#ffd740;
  --mat-slider-hover-state-layer-color:rgba(255, 215, 64, 0.05);
  --mat-slider-focus-state-layer-color:rgba(255, 215, 64, 0.2);
  --mdc-slider-handle-color:#ffd740;
  --mdc-slider-focus-handle-color:#ffd740;
  --mdc-slider-hover-handle-color:#ffd740;
  --mdc-slider-active-track-color:#ffd740;
  --mdc-slider-inactive-track-color:#ffd740;
  --mdc-slider-with-tick-marks-inactive-container-color:#ffd740;
  --mdc-slider-with-tick-marks-active-container-color:rgba(0, 0, 0, 0.87);
}

html .mat-warn {
  --mat-slider-ripple-color:#f44336;
  --mat-slider-hover-state-layer-color:rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color:rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color:#f44336;
  --mdc-slider-focus-handle-color:#f44336;
  --mdc-slider-hover-handle-color:#f44336;
  --mdc-slider-active-track-color:#f44336;
  --mdc-slider-inactive-track-color:#f44336;
  --mdc-slider-with-tick-marks-inactive-container-color:#f44336;
  --mdc-slider-with-tick-marks-active-container-color:white;
}

html {
  --mdc-slider-label-label-text-font:Roboto, sans-serif;
  --mdc-slider-label-label-text-size:14px;
  --mdc-slider-label-label-text-line-height:22px;
  --mdc-slider-label-label-text-tracking:0.0071428571em;
  --mdc-slider-label-label-text-weight:500;
}

html {
  --mat-menu-container-shape:4px;
  --mat-menu-divider-bottom-spacing:0;
  --mat-menu-divider-top-spacing:0;
  --mat-menu-item-spacing:16px;
  --mat-menu-item-icon-size:24px;
  --mat-menu-item-leading-spacing:16px;
  --mat-menu-item-trailing-spacing:16px;
  --mat-menu-item-with-icon-leading-spacing:16px;
  --mat-menu-item-with-icon-trailing-spacing:16px;
}

html {
  --mat-menu-item-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color:rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-menu-container-color:white;
  --mat-menu-divider-color:rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font:Roboto, sans-serif;
  --mat-menu-item-label-text-size:16px;
  --mat-menu-item-label-text-tracking:0.03125em;
  --mat-menu-item-label-text-line-height:24px;
  --mat-menu-item-label-text-weight:400;
}

html {
  --mdc-list-list-item-container-shape:0;
  --mdc-list-list-item-leading-avatar-shape:50%;
  --mdc-list-list-item-container-color:transparent;
  --mdc-list-list-item-selected-container-color:transparent;
  --mdc-list-list-item-leading-avatar-color:transparent;
  --mdc-list-list-item-leading-icon-size:24px;
  --mdc-list-list-item-leading-avatar-size:40px;
  --mdc-list-list-item-trailing-icon-size:24px;
  --mdc-list-list-item-disabled-state-layer-color:transparent;
  --mdc-list-list-item-disabled-state-layer-opacity:0;
  --mdc-list-list-item-disabled-label-text-opacity:0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity:0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity:0.38;
  --mat-list-active-indicator-color:transparent;
  --mat-list-active-indicator-shape:4px;
}

html {
  --mdc-list-list-item-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color:rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color:black;
  --mdc-list-list-item-disabled-leading-icon-color:black;
  --mdc-list-list-item-disabled-trailing-icon-color:black;
  --mdc-list-list-item-hover-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color:rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color:black;
  --mdc-list-list-item-hover-state-layer-opacity:0.04;
  --mdc-list-list-item-focus-state-layer-color:black;
  --mdc-list-list-item-focus-state-layer-opacity:0.12;
}

.mdc-list-item__start, .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#673ab7;
  --mdc-radio-selected-hover-icon-color:#673ab7;
  --mdc-radio-selected-icon-color:#673ab7;
  --mdc-radio-selected-pressed-icon-color:#673ab7;
}

.mat-accent .mdc-list-item__start, .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#ffd740;
  --mdc-radio-selected-hover-icon-color:#ffd740;
  --mdc-radio-selected-icon-color:#ffd740;
  --mdc-radio-selected-pressed-icon-color:#ffd740;
}

.mat-warn .mdc-list-item__start, .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color:black;
  --mdc-radio-disabled-unselected-icon-color:black;
  --mdc-radio-unselected-hover-icon-color:#212121;
  --mdc-radio-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color:#f44336;
  --mdc-radio-selected-hover-icon-color:#f44336;
  --mdc-radio-selected-icon-color:#f44336;
  --mdc-radio-selected-pressed-icon-color:#f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:white;
  --mdc-checkbox-selected-focus-icon-color:#673ab7;
  --mdc-checkbox-selected-hover-icon-color:#673ab7;
  --mdc-checkbox-selected-icon-color:#673ab7;
  --mdc-checkbox-selected-pressed-icon-color:#673ab7;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#673ab7;
  --mdc-checkbox-selected-hover-state-layer-color:#673ab7;
  --mdc-checkbox-selected-pressed-state-layer-color:#673ab7;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:black;
  --mdc-checkbox-selected-focus-icon-color:#ffd740;
  --mdc-checkbox-selected-hover-icon-color:#ffd740;
  --mdc-checkbox-selected-icon-color:#ffd740;
  --mdc-checkbox-selected-pressed-icon-color:#ffd740;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#ffd740;
  --mdc-checkbox-selected-hover-state-layer-color:#ffd740;
  --mdc-checkbox-selected-pressed-state-layer-color:#ffd740;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:white;
  --mdc-checkbox-selected-focus-icon-color:#f44336;
  --mdc-checkbox-selected-hover-icon-color:#f44336;
  --mdc-checkbox-selected-icon-color:#f44336;
  --mdc-checkbox-selected-pressed-icon-color:#f44336;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#f44336;
  --mdc-checkbox-selected-hover-state-layer-color:#f44336;
  --mdc-checkbox-selected-pressed-state-layer-color:#f44336;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #673ab7;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #673ab7;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content, .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height:48px;
  --mdc-list-list-item-two-line-container-height:64px;
  --mdc-list-list-item-three-line-container-height:88px;
  --mat-list-list-item-leading-icon-start-space:16px;
  --mat-list-list-item-leading-icon-end-space:32px;
}

.mdc-list-item__start, .mdc-list-item__end {
  --mdc-radio-state-layer-size:40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font:Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height:24px;
  --mdc-list-list-item-label-text-size:16px;
  --mdc-list-list-item-label-text-tracking:0.03125em;
  --mdc-list-list-item-label-text-weight:400;
  --mdc-list-list-item-supporting-text-font:Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height:20px;
  --mdc-list-list-item-supporting-text-size:14px;
  --mdc-list-list-item-supporting-text-tracking:0.0178571429em;
  --mdc-list-list-item-supporting-text-weight:400;
  --mdc-list-list-item-trailing-supporting-text-font:Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height:20px;
  --mdc-list-list-item-trailing-supporting-text-size:12px;
  --mdc-list-list-item-trailing-supporting-text-tracking:0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight:400;
}

.mdc-list-group__subheader {
  font: 400 16px/28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color:rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color:white;
  --mat-paginator-enabled-icon-color:rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color:rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size:56px;
  --mat-paginator-form-field-container-height:40px;
  --mat-paginator-form-field-container-vertical-padding:8px;
}

html {
  --mat-paginator-container-text-font:Roboto, sans-serif;
  --mat-paginator-container-text-line-height:20px;
  --mat-paginator-container-text-size:12px;
  --mat-paginator-container-text-tracking:0.0333333333em;
  --mat-paginator-container-text-weight:400;
  --mat-paginator-select-trigger-text-size:12px;
}

html {
  --mdc-tab-indicator-active-indicator-height:2px;
  --mdc-tab-indicator-active-indicator-shape:0;
  --mdc-secondary-navigation-tab-container-height:48px;
  --mat-tab-header-divider-color:transparent;
  --mat-tab-header-divider-height:0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color:#673ab7;
  --mat-tab-header-disabled-ripple-color:rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color:black;
  --mat-tab-header-inactive-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color:#673ab7;
  --mat-tab-header-active-ripple-color:#673ab7;
  --mat-tab-header-inactive-ripple-color:#673ab7;
  --mat-tab-header-inactive-focus-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color:#673ab7;
  --mat-tab-header-active-hover-label-text-color:#673ab7;
  --mat-tab-header-active-focus-indicator-color:#673ab7;
  --mat-tab-header-active-hover-indicator-color:#673ab7;
}

.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color:#ffd740;
  --mat-tab-header-disabled-ripple-color:rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color:black;
  --mat-tab-header-inactive-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color:#ffd740;
  --mat-tab-header-active-ripple-color:#ffd740;
  --mat-tab-header-inactive-ripple-color:#ffd740;
  --mat-tab-header-inactive-focus-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color:#ffd740;
  --mat-tab-header-active-hover-label-text-color:#ffd740;
  --mat-tab-header-active-focus-indicator-color:#ffd740;
  --mat-tab-header-active-hover-indicator-color:#ffd740;
}

.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color:#f44336;
  --mat-tab-header-disabled-ripple-color:rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color:black;
  --mat-tab-header-inactive-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color:#f44336;
  --mat-tab-header-active-ripple-color:#f44336;
  --mat-tab-header-inactive-ripple-color:#f44336;
  --mat-tab-header-inactive-focus-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color:rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color:#f44336;
  --mat-tab-header-active-hover-label-text-color:#f44336;
  --mat-tab-header-active-focus-indicator-color:#f44336;
  --mat-tab-header-active-hover-indicator-color:#f44336;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color:#673ab7;
  --mat-tab-header-with-background-foreground-color:white;
}

.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color:#ffd740;
  --mat-tab-header-with-background-foreground-color:rgba(0, 0, 0, 0.87);
}

.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color:#f44336;
  --mat-tab-header-with-background-foreground-color:white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height:48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font:Roboto, sans-serif;
  --mat-tab-header-label-text-size:14px;
  --mat-tab-header-label-text-tracking:0.0892857143em;
  --mat-tab-header-label-text-line-height:36px;
  --mat-tab-header-label-text-weight:500;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color:#fff;
  --mdc-checkbox-selected-focus-state-layer-opacity:0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity:0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity:0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity:0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity:0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity:0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:black;
  --mdc-checkbox-selected-focus-icon-color:#ffd740;
  --mdc-checkbox-selected-hover-icon-color:#ffd740;
  --mdc-checkbox-selected-icon-color:#ffd740;
  --mdc-checkbox-selected-pressed-icon-color:#ffd740;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#ffd740;
  --mdc-checkbox-selected-hover-state-layer-color:#ffd740;
  --mdc-checkbox-selected-pressed-state-layer-color:#ffd740;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
  --mat-checkbox-disabled-label-color:rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color:rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:white;
  --mdc-checkbox-selected-focus-icon-color:#673ab7;
  --mdc-checkbox-selected-hover-icon-color:#673ab7;
  --mdc-checkbox-selected-icon-color:#673ab7;
  --mdc-checkbox-selected-pressed-icon-color:#673ab7;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#673ab7;
  --mdc-checkbox-selected-hover-state-layer-color:#673ab7;
  --mdc-checkbox-selected-pressed-state-layer-color:#673ab7;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
}

.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color:rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color:white;
  --mdc-checkbox-selected-focus-icon-color:#f44336;
  --mdc-checkbox-selected-hover-icon-color:#f44336;
  --mdc-checkbox-selected-icon-color:#f44336;
  --mdc-checkbox-selected-pressed-icon-color:#f44336;
  --mdc-checkbox-unselected-focus-icon-color:#212121;
  --mdc-checkbox-unselected-hover-icon-color:#212121;
  --mdc-checkbox-unselected-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color:rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color:#f44336;
  --mdc-checkbox-selected-hover-state-layer-color:#f44336;
  --mdc-checkbox-selected-pressed-state-layer-color:#f44336;
  --mdc-checkbox-unselected-focus-state-layer-color:black;
  --mdc-checkbox-unselected-hover-state-layer-color:black;
  --mdc-checkbox-unselected-pressed-state-layer-color:black;
}

html {
  --mdc-checkbox-state-layer-size:40px;
  --mat-checkbox-touch-target-display:block;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font:Roboto, sans-serif;
  --mdc-form-field-label-text-line-height:20px;
  --mdc-form-field-label-text-size:14px;
  --mdc-form-field-label-text-tracking:0.0178571429em;
  --mdc-form-field-label-text-weight:400;
}

html {
  --mdc-text-button-container-shape:4px;
  --mdc-text-button-keep-touch-target:false;
  --mdc-filled-button-container-shape:4px;
  --mdc-filled-button-keep-touch-target:false;
  --mdc-protected-button-container-shape:4px;
  --mdc-protected-button-keep-touch-target:false;
  --mdc-outlined-button-keep-touch-target:false;
  --mdc-outlined-button-outline-width:1px;
  --mdc-outlined-button-container-shape:4px;
  --mat-text-button-horizontal-padding:8px;
  --mat-text-button-with-icon-horizontal-padding:8px;
  --mat-text-button-icon-spacing:8px;
  --mat-text-button-icon-offset:0;
  --mat-filled-button-horizontal-padding:16px;
  --mat-filled-button-icon-spacing:8px;
  --mat-filled-button-icon-offset:-4px;
  --mat-protected-button-horizontal-padding:16px;
  --mat-protected-button-icon-spacing:8px;
  --mat-protected-button-icon-offset:-4px;
  --mat-outlined-button-horizontal-padding:15px;
  --mat-outlined-button-icon-spacing:8px;
  --mat-outlined-button-icon-offset:-4px;
}

html {
  --mdc-text-button-label-text-color:black;
  --mdc-text-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color:black;
  --mat-text-button-disabled-state-layer-color:black;
  --mat-text-button-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity:0.04;
  --mat-text-button-focus-state-layer-opacity:0.12;
  --mat-text-button-pressed-state-layer-opacity:0.12;
  --mdc-filled-button-container-color:white;
  --mdc-filled-button-label-text-color:black;
  --mdc-filled-button-disabled-container-color:rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color:black;
  --mat-filled-button-disabled-state-layer-color:black;
  --mat-filled-button-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity:0.04;
  --mat-filled-button-focus-state-layer-opacity:0.12;
  --mat-filled-button-pressed-state-layer-opacity:0.12;
  --mdc-protected-button-container-color:white;
  --mdc-protected-button-label-text-color:black;
  --mdc-protected-button-disabled-container-color:rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color:#000;
  --mat-protected-button-state-layer-color:black;
  --mat-protected-button-disabled-state-layer-color:black;
  --mat-protected-button-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity:0.04;
  --mat-protected-button-focus-state-layer-opacity:0.12;
  --mat-protected-button-pressed-state-layer-opacity:0.12;
  --mdc-outlined-button-disabled-outline-color:rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color:rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color:black;
  --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color:black;
  --mat-outlined-button-disabled-state-layer-color:black;
  --mat-outlined-button-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity:0.04;
  --mat-outlined-button-focus-state-layer-opacity:0.12;
  --mat-outlined-button-pressed-state-layer-opacity:0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color:#673ab7;
  --mat-text-button-state-layer-color:#673ab7;
  --mat-text-button-ripple-color:rgba(103, 58, 183, 0.1);
}

.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color:#ffd740;
  --mat-text-button-state-layer-color:#ffd740;
  --mat-text-button-ripple-color:rgba(255, 215, 64, 0.1);
}

.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color:#f44336;
  --mat-text-button-state-layer-color:#f44336;
  --mat-text-button-ripple-color:rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color:#673ab7;
  --mdc-filled-button-label-text-color:white;
  --mat-filled-button-state-layer-color:white;
  --mat-filled-button-ripple-color:rgba(255, 255, 255, 0.1);
}

.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color:#ffd740;
  --mdc-filled-button-label-text-color:black;
  --mat-filled-button-state-layer-color:black;
  --mat-filled-button-ripple-color:rgba(0, 0, 0, 0.1);
}

.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color:#f44336;
  --mdc-filled-button-label-text-color:white;
  --mat-filled-button-state-layer-color:white;
  --mat-filled-button-ripple-color:rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color:#673ab7;
  --mdc-protected-button-label-text-color:white;
  --mat-protected-button-state-layer-color:white;
  --mat-protected-button-ripple-color:rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color:#ffd740;
  --mdc-protected-button-label-text-color:black;
  --mat-protected-button-state-layer-color:black;
  --mat-protected-button-ripple-color:rgba(0, 0, 0, 0.1);
}

.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color:#f44336;
  --mdc-protected-button-label-text-color:white;
  --mat-protected-button-state-layer-color:white;
  --mat-protected-button-ripple-color:rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color:#673ab7;
  --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color:#673ab7;
  --mat-outlined-button-ripple-color:rgba(103, 58, 183, 0.1);
}

.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color:#ffd740;
  --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color:#ffd740;
  --mat-outlined-button-ripple-color:rgba(255, 215, 64, 0.1);
}

.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color:#f44336;
  --mdc-outlined-button-outline-color:rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color:#f44336;
  --mat-outlined-button-ripple-color:rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height:36px;
  --mdc-filled-button-container-height:36px;
  --mdc-outlined-button-container-height:36px;
  --mdc-protected-button-container-height:36px;
  --mat-text-button-touch-target-display:block;
  --mat-filled-button-touch-target-display:block;
  --mat-protected-button-touch-target-display:block;
  --mat-outlined-button-touch-target-display:block;
}

html {
  --mdc-text-button-label-text-font:Roboto, sans-serif;
  --mdc-text-button-label-text-size:14px;
  --mdc-text-button-label-text-tracking:0.0892857143em;
  --mdc-text-button-label-text-weight:500;
  --mdc-text-button-label-text-transform:none;
  --mdc-filled-button-label-text-font:Roboto, sans-serif;
  --mdc-filled-button-label-text-size:14px;
  --mdc-filled-button-label-text-tracking:0.0892857143em;
  --mdc-filled-button-label-text-weight:500;
  --mdc-filled-button-label-text-transform:none;
  --mdc-outlined-button-label-text-font:Roboto, sans-serif;
  --mdc-outlined-button-label-text-size:14px;
  --mdc-outlined-button-label-text-tracking:0.0892857143em;
  --mdc-outlined-button-label-text-weight:500;
  --mdc-outlined-button-label-text-transform:none;
  --mdc-protected-button-label-text-font:Roboto, sans-serif;
  --mdc-protected-button-label-text-size:14px;
  --mdc-protected-button-label-text-tracking:0.0892857143em;
  --mdc-protected-button-label-text-weight:500;
  --mdc-protected-button-label-text-transform:none;
}

html {
  --mdc-icon-button-icon-size:24px;
}

html {
  --mdc-icon-button-icon-color:inherit;
  --mdc-icon-button-disabled-icon-color:rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color:black;
  --mat-icon-button-disabled-state-layer-color:black;
  --mat-icon-button-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity:0.04;
  --mat-icon-button-focus-state-layer-opacity:0.12;
  --mat-icon-button-pressed-state-layer-opacity:0.12;
}

html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color:#673ab7;
  --mat-icon-button-state-layer-color:#673ab7;
  --mat-icon-button-ripple-color:rgba(103, 58, 183, 0.1);
}

html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color:#ffd740;
  --mat-icon-button-state-layer-color:#ffd740;
  --mat-icon-button-ripple-color:rgba(255, 215, 64, 0.1);
}

html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color:#f44336;
  --mat-icon-button-state-layer-color:#f44336;
  --mat-icon-button-ripple-color:rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display:block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size:48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape:50%;
  --mdc-fab-icon-size:24px;
  --mdc-fab-small-container-shape:50%;
  --mdc-fab-small-icon-size:24px;
  --mdc-extended-fab-container-height:48px;
  --mdc-extended-fab-container-shape:24px;
}

html {
  --mdc-fab-container-color:white;
  --mdc-fab-container-elevation-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color:#000;
  --mat-fab-foreground-color:black;
  --mat-fab-state-layer-color:black;
  --mat-fab-disabled-state-layer-color:black;
  --mat-fab-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity:0.04;
  --mat-fab-focus-state-layer-opacity:0.12;
  --mat-fab-pressed-state-layer-opacity:0.12;
  --mat-fab-disabled-state-container-color:rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color:rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color:white;
  --mdc-fab-small-container-elevation-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color:#000;
  --mat-fab-small-foreground-color:black;
  --mat-fab-small-state-layer-color:black;
  --mat-fab-small-disabled-state-layer-color:black;
  --mat-fab-small-ripple-color:rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity:0.04;
  --mat-fab-small-focus-state-layer-opacity:0.12;
  --mat-fab-small-pressed-state-layer-opacity:0.12;
  --mat-fab-small-disabled-state-container-color:rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color:rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color:#000;
}

html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color:#673ab7;
  --mat-fab-foreground-color:white;
  --mat-fab-state-layer-color:white;
  --mat-fab-ripple-color:rgba(255, 255, 255, 0.1);
}

html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color:#ffd740;
  --mat-fab-foreground-color:black;
  --mat-fab-state-layer-color:black;
  --mat-fab-ripple-color:rgba(0, 0, 0, 0.1);
}

html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color:#f44336;
  --mat-fab-foreground-color:white;
  --mat-fab-state-layer-color:white;
  --mat-fab-ripple-color:rgba(255, 255, 255, 0.1);
}

html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color:#673ab7;
  --mat-fab-small-foreground-color:white;
  --mat-fab-small-state-layer-color:white;
  --mat-fab-small-ripple-color:rgba(255, 255, 255, 0.1);
}

html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color:#ffd740;
  --mat-fab-small-foreground-color:black;
  --mat-fab-small-state-layer-color:black;
  --mat-fab-small-ripple-color:rgba(0, 0, 0, 0.1);
}

html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color:#f44336;
  --mat-fab-small-foreground-color:white;
  --mat-fab-small-state-layer-color:white;
  --mat-fab-small-ripple-color:rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display:block;
  --mat-fab-small-touch-target-display:block;
}

html {
  --mdc-extended-fab-label-text-font:Roboto, sans-serif;
  --mdc-extended-fab-label-text-size:14px;
  --mdc-extended-fab-label-text-tracking:0.0892857143em;
  --mdc-extended-fab-label-text-weight:500;
}

html {
  --mdc-snackbar-container-shape:4px;
}

html {
  --mdc-snackbar-container-color:#333333;
  --mdc-snackbar-supporting-text-color:rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color:#ffd740;
}

html {
  --mdc-snackbar-supporting-text-font:Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height:20px;
  --mdc-snackbar-supporting-text-size:14px;
  --mdc-snackbar-supporting-text-weight:400;
}

html {
  --mat-table-row-item-outline-width:1px;
}

html {
  --mat-table-background-color:white;
  --mat-table-header-headline-color:rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color:rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height:56px;
  --mat-table-footer-container-height:52px;
  --mat-table-row-item-container-height:52px;
}

html {
  --mat-table-header-headline-font:Roboto, sans-serif;
  --mat-table-header-headline-line-height:22px;
  --mat-table-header-headline-size:14px;
  --mat-table-header-headline-weight:500;
  --mat-table-header-headline-tracking:0.0071428571em;
  --mat-table-row-item-label-text-font:Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height:20px;
  --mat-table-row-item-label-text-size:14px;
  --mat-table-row-item-label-text-weight:400;
  --mat-table-row-item-label-text-tracking:0.0178571429em;
  --mat-table-footer-supporting-text-font:Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height:20px;
  --mat-table-footer-supporting-text-size:14px;
  --mat-table-footer-supporting-text-weight:400;
  --mat-table-footer-supporting-text-tracking:0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width:4px;
  --mdc-circular-progress-size:48px;
}

html {
  --mdc-circular-progress-active-indicator-color:#673ab7;
}

html .mat-accent {
  --mdc-circular-progress-active-indicator-color:#ffd740;
}

html .mat-warn {
  --mdc-circular-progress-active-indicator-color:#f44336;
}

html {
  --mat-badge-container-shape:50%;
  --mat-badge-container-size:unset;
  --mat-badge-small-size-container-size:unset;
  --mat-badge-large-size-container-size:unset;
  --mat-badge-legacy-container-size:22px;
  --mat-badge-legacy-small-size-container-size:16px;
  --mat-badge-legacy-large-size-container-size:28px;
  --mat-badge-container-offset:-11px 0;
  --mat-badge-small-size-container-offset:-8px 0;
  --mat-badge-large-size-container-offset:-14px 0;
  --mat-badge-container-overlap-offset:-11px;
  --mat-badge-small-size-container-overlap-offset:-8px;
  --mat-badge-large-size-container-overlap-offset:-14px;
  --mat-badge-container-padding:0;
  --mat-badge-small-size-container-padding:0;
  --mat-badge-large-size-container-padding:0;
}

html {
  --mat-badge-background-color:#673ab7;
  --mat-badge-text-color:white;
  --mat-badge-disabled-state-background-color:#b9b9b9;
  --mat-badge-disabled-state-text-color:rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color:#ffd740;
  --mat-badge-text-color:rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color:#f44336;
  --mat-badge-text-color:white;
}

html {
  --mat-badge-text-font:Roboto, sans-serif;
  --mat-badge-text-size:12px;
  --mat-badge-text-weight:600;
  --mat-badge-small-size-text-size:9px;
  --mat-badge-large-size-text-size:24px;
}

html {
  --mat-bottom-sheet-container-shape:4px;
}

html {
  --mat-bottom-sheet-container-text-color:rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color:white;
}

html {
  --mat-bottom-sheet-container-text-font:Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height:20px;
  --mat-bottom-sheet-container-text-size:14px;
  --mat-bottom-sheet-container-text-tracking:0.0178571429em;
  --mat-bottom-sheet-container-text-weight:400;
}

html {
  --mat-legacy-button-toggle-height:36px;
  --mat-legacy-button-toggle-shape:2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity:1;
  --mat-standard-button-toggle-shape:4px;
  --mat-standard-button-toggle-hover-state-layer-opacity:0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity:0.12;
}

html {
  --mat-legacy-button-toggle-text-color:rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color:rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color:rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color:#e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color:rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color:#eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color:#bdbdbd;
  --mat-standard-button-toggle-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color:white;
  --mat-standard-button-toggle-state-layer-color:black;
  --mat-standard-button-toggle-selected-state-background-color:#e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color:rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color:white;
  --mat-standard-button-toggle-disabled-selected-state-text-color:rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color:#bdbdbd;
  --mat-standard-button-toggle-divider-color:#e0e0e0;
}

html {
  --mat-standard-button-toggle-height:48px;
}

html {
  --mat-legacy-button-toggle-label-text-font:Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height:24px;
  --mat-legacy-button-toggle-label-text-size:16px;
  --mat-legacy-button-toggle-label-text-tracking:0.03125em;
  --mat-legacy-button-toggle-label-text-weight:400;
  --mat-standard-button-toggle-label-text-font:Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height:24px;
  --mat-standard-button-toggle-label-text-size:16px;
  --mat-standard-button-toggle-label-text-tracking:0.03125em;
  --mat-standard-button-toggle-label-text-weight:400;
}

html {
  --mat-datepicker-calendar-container-shape:4px;
  --mat-datepicker-calendar-container-touch-shape:4px;
  --mat-datepicker-calendar-container-elevation-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color:white;
  --mat-datepicker-calendar-date-selected-state-background-color:#673ab7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(103, 58, 183, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color:white;
  --mat-datepicker-calendar-date-focus-state-background-color:rgba(103, 58, 183, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color:rgba(103, 58, 183, 0.3);
  --mat-datepicker-toggle-active-state-icon-color:#673ab7;
  --mat-datepicker-calendar-date-in-range-state-background-color:rgba(103, 58, 183, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e;
  --mat-datepicker-toggle-icon-color:rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color:rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color:black;
  --mat-datepicker-calendar-period-button-icon-color:rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color:rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color:rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color:rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color:rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color:rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color:rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color:transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color:rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color:rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color:rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color:rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color:rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color:white;
  --mat-datepicker-calendar-container-text-color:rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color:rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color:#ffd740;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(255, 215, 64, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color:rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color:rgba(255, 215, 64, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color:rgba(255, 215, 64, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color:rgba(255, 215, 64, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e;
}

.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color:white;
  --mat-datepicker-calendar-date-selected-state-background-color:#f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color:rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color:white;
  --mat-datepicker-calendar-date-focus-state-background-color:rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color:rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color:rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color:rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color:#a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color:#46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color:#ffd740;
}

.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color:#f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display:none;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size:40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font:Roboto, sans-serif;
  --mat-datepicker-calendar-text-size:13px;
  --mat-datepicker-calendar-body-label-text-size:14px;
  --mat-datepicker-calendar-body-label-text-weight:500;
  --mat-datepicker-calendar-period-button-text-size:14px;
  --mat-datepicker-calendar-period-button-text-weight:500;
  --mat-datepicker-calendar-header-text-size:11px;
  --mat-datepicker-calendar-header-text-weight:400;
}

html {
  --mat-divider-width:1px;
}

html {
  --mat-divider-color:rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape:4px;
  --mat-expansion-legacy-header-indicator-display:inline-block;
  --mat-expansion-header-indicator-display:none;
}

html {
  --mat-expansion-container-background-color:white;
  --mat-expansion-container-text-color:rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color:rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color:rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color:rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color:rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color:rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height:48px;
  --mat-expansion-header-expanded-state-height:64px;
}

html {
  --mat-expansion-header-text-font:Roboto, sans-serif;
  --mat-expansion-header-text-size:14px;
  --mat-expansion-header-text-weight:500;
  --mat-expansion-header-text-line-height:inherit;
  --mat-expansion-header-text-tracking:inherit;
  --mat-expansion-container-text-font:Roboto, sans-serif;
  --mat-expansion-container-text-line-height:20px;
  --mat-expansion-container-text-size:14px;
  --mat-expansion-container-text-tracking:0.0178571429em;
  --mat-expansion-container-text-weight:400;
}

html {
  --mat-grid-list-tile-header-primary-text-size:14px;
  --mat-grid-list-tile-header-secondary-text-size:12px;
  --mat-grid-list-tile-footer-primary-text-size:14px;
  --mat-grid-list-tile-footer-secondary-text-size:12px;
}

html {
  --mat-icon-color:inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color:#673ab7;
}

.mat-icon.mat-accent {
  --mat-icon-color:#ffd740;
}

.mat-icon.mat-warn {
  --mat-icon-color:#f44336;
}

html {
  --mat-sidenav-container-shape:0;
  --mat-sidenav-container-elevation-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width:auto;
}

html {
  --mat-sidenav-container-divider-color:rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color:white;
  --mat-sidenav-container-text-color:rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color:#fafafa;
  --mat-sidenav-content-text-color:rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color:rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color:white;
  --mat-stepper-header-selected-state-icon-background-color:#673ab7;
  --mat-stepper-header-selected-state-icon-foreground-color:white;
  --mat-stepper-header-done-state-icon-background-color:#673ab7;
  --mat-stepper-header-done-state-icon-foreground-color:white;
  --mat-stepper-header-edit-state-icon-background-color:#673ab7;
  --mat-stepper-header-edit-state-icon-foreground-color:white;
  --mat-stepper-container-color:white;
  --mat-stepper-line-color:rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color:rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color:rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color:rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color:rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color:#f44336;
  --mat-stepper-header-icon-background-color:rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color:#f44336;
  --mat-stepper-header-error-state-icon-background-color:transparent;
}

html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color:rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color:#ffd740;
  --mat-stepper-header-selected-state-icon-foreground-color:rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color:#ffd740;
  --mat-stepper-header-done-state-icon-foreground-color:rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color:#ffd740;
  --mat-stepper-header-edit-state-icon-foreground-color:rgba(0, 0, 0, 0.87);
}

html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color:white;
  --mat-stepper-header-selected-state-icon-background-color:#f44336;
  --mat-stepper-header-selected-state-icon-foreground-color:white;
  --mat-stepper-header-done-state-icon-background-color:#f44336;
  --mat-stepper-header-done-state-icon-foreground-color:white;
  --mat-stepper-header-edit-state-icon-background-color:#f44336;
  --mat-stepper-header-edit-state-icon-foreground-color:white;
}

html {
  --mat-stepper-header-height:72px;
}

html {
  --mat-stepper-container-text-font:Roboto, sans-serif;
  --mat-stepper-header-label-text-font:Roboto, sans-serif;
  --mat-stepper-header-label-text-size:14px;
  --mat-stepper-header-label-text-weight:400;
  --mat-stepper-header-error-state-label-text-size:16px;
  --mat-stepper-header-selected-state-label-text-size:16px;
  --mat-stepper-header-selected-state-label-text-weight:400;
}

html {
  --mat-sort-arrow-color:#757575;
}

html {
  --mat-toolbar-container-background-color:whitesmoke;
  --mat-toolbar-container-text-color:rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color:#673ab7;
  --mat-toolbar-container-text-color:white;
}

.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color:#ffd740;
  --mat-toolbar-container-text-color:rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color:#f44336;
  --mat-toolbar-container-text-color:white;
}

html {
  --mat-toolbar-standard-height:64px;
  --mat-toolbar-mobile-height:56px;
}

html {
  --mat-toolbar-title-text-font:Roboto, sans-serif;
  --mat-toolbar-title-text-line-height:32px;
  --mat-toolbar-title-text-size:20px;
  --mat-toolbar-title-text-tracking:0.0125em;
  --mat-toolbar-title-text-weight:500;
}

html {
  --mat-tree-container-background-color:white;
  --mat-tree-node-text-color:rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height:48px;
}

html {
  --mat-tree-node-text-font:Roboto, sans-serif;
  --mat-tree-node-text-size:14px;
  --mat-tree-node-text-weight:400;
}

.mat-h1, .mat-headline-5, .mat-typography .mat-h1, .mat-typography .mat-headline-5, .mat-typography h1 {
  font: 400 24px/32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-headline-6, .mat-typography .mat-h2, .mat-typography .mat-headline-6, .mat-typography h2 {
  font: 500 20px/32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3, .mat-subtitle-1, .mat-typography .mat-h3, .mat-typography .mat-subtitle-1, .mat-typography h3 {
  font: 400 16px/28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4 {
  font: 400 16px/24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography .mat-h5, .mat-typography h5 {
  font: 400 11.62px/20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
  font: 400 9.38px/20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-subtitle-2, .mat-typography .mat-body-strong, .mat-typography .mat-subtitle-2 {
  font: 500 14px/22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography {
  font: 400 14px/20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}

.mat-body p, .mat-body-2 p, .mat-typography .mat-body p, .mat-typography .mat-body-2 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption, .mat-typography .mat-small, .mat-typography .mat-caption {
  font: 400 12px/20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1, .mat-typography .mat-headline-1 {
  font: 300 96px/96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2, .mat-typography .mat-headline-2 {
  font: 300 60px/60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3, .mat-typography .mat-headline-3 {
  font: 400 48px/50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4, .mat-typography .mat-headline-4 {
  font: 400 34px/40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

:root {
  --primary-rgb: 28 138 167;
  --primary: rgb(var(--primary-rgb));
  --sidebar-background: rgb(var(--primary-rgb) / 0.07);
  --active-rgb: 22 101 52;
  --active: rgb(var(--active-rgb));
  --active-background: #dcfce7;
  --disabled-rgb: 153 27 27;
  --disabled: rgb(var(--disabled-rgb));
  --disabled-background: rgb(var(--disabled-rgb) / 0.1);
  --warning-rgb: 154 52 18;
  --warning: rgb(var(--warning-rgb));
  --warning-background: #FFEDD5;
  --danger-rgb: 216 72 53;
  --danger: rgb(var(--danger-rgb));
  --subtle-border: #f4f4f4;
  --row-hover: #f5f5f5;
}

:root {
  --container-radius: 12px;
  --control-radius: 10px;
}

:root {
  --font-family-body: Inter, "Helvetica Neue", sans-serif;
  --font-size-medium: 1.6rem;
  --font-size-small: 1.4rem;
  --font-size-smaller: 1.1rem;
}

:root {
  --golden-ratio: 1.618;
  --golden-ratio-inverse: calc(1 / var(--golden-ratio));
}

:root {
  --page-background: #FAFAFA;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html,
body {
  background: var(--page-background);
}

html {
  font-size: 10px;
}

body {
  color: var(--text-color);
  font-family: var(--font-family-body);
  line-height: 1.33;
}

/* For use in src/lib/core/theming/_palette.scss */
html {
  --mat-badge-text-font: Inter;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Inter;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Inter;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Inter;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Inter;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Inter;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Inter;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Inter;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Inter;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Inter;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Inter;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Inter;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Inter;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Inter;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Inter;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Inter;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Inter;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Inter;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Inter;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Inter;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Inter;
  --mat-stepper-header-label-text-font: Inter;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Inter;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Inter;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Inter;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Inter;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Inter;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Inter;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Inter;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Inter;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Inter;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Inter;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Inter;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Inter;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Inter;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Inter;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Inter;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Inter;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Inter;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Inter;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Inter;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Inter;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Inter;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Inter;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Inter;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Inter;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Inter;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Inter;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Inter;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Inter;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Inter;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Inter;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Inter;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Inter;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Inter;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Inter;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Inter;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #1c8aa7;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: white;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #1c8aa7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #1c8aa7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: white;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: white;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: white;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: white;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #1c8aa7;
  --mdc-linear-progress-track-color: rgba(28, 138, 167, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: white;
  --mdc-linear-progress-track-color: rgba(255, 255, 255, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #1c8aa7;
  --mdc-filled-text-field-focus-active-indicator-color: #1c8aa7;
  --mdc-filled-text-field-focus-label-text-color: rgba(28, 138, 167, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #1c8aa7;
  --mdc-outlined-text-field-focus-outline-color: #1c8aa7;
  --mdc-outlined-text-field-focus-label-text-color: rgba(28, 138, 167, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(28, 138, 167, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: white;
  --mdc-filled-text-field-focus-active-indicator-color: white;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-caret-color: white;
  --mdc-outlined-text-field-focus-outline-color: white;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(255, 255, 255, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(28, 138, 167, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 255, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #1c8aa7;
  --mdc-chip-elevated-selected-container-color: #1c8aa7;
  --mdc-chip-elevated-disabled-container-color: #1c8aa7;
  --mdc-chip-flat-disabled-selected-container-color: #1c8aa7;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: black;
  --mdc-chip-elevated-container-color: white;
  --mdc-chip-elevated-selected-container-color: white;
  --mdc-chip-elevated-disabled-container-color: white;
  --mdc-chip-flat-disabled-selected-container-color: white;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: black;
  --mdc-chip-selected-label-text-color: black;
  --mdc-chip-with-icon-icon-color: black;
  --mdc-chip-with-icon-disabled-icon-color: black;
  --mdc-chip-with-icon-selected-icon-color: black;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: black;
  --mdc-chip-with-trailing-icon-trailing-icon-color: black;
  --mat-chip-selected-disabled-trailing-icon-color: black;
  --mat-chip-selected-trailing-icon-color: black;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #19829f;
  --mdc-switch-selected-handle-color: #19829f;
  --mdc-switch-selected-hover-state-layer-color: #19829f;
  --mdc-switch-selected-pressed-state-layer-color: #19829f;
  --mdc-switch-selected-focus-handle-color: #095a7c;
  --mdc-switch-selected-hover-handle-color: #095a7c;
  --mdc-switch-selected-pressed-handle-color: #095a7c;
  --mdc-switch-selected-focus-track-color: #60adc1;
  --mdc-switch-selected-hover-track-color: #60adc1;
  --mdc-switch-selected-pressed-track-color: #60adc1;
  --mdc-switch-selected-track-color: #60adc1;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: white;
  --mdc-switch-selected-handle-color: white;
  --mdc-switch-selected-hover-state-layer-color: white;
  --mdc-switch-selected-pressed-state-layer-color: white;
  --mdc-switch-selected-focus-handle-color: white;
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-pressed-handle-color: white;
  --mdc-switch-selected-focus-track-color: white;
  --mdc-switch-selected-hover-track-color: white;
  --mdc-switch-selected-pressed-track-color: white;
  --mdc-switch-selected-track-color: white;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c8aa7;
  --mdc-radio-selected-hover-icon-color: #1c8aa7;
  --mdc-radio-selected-icon-color: #1c8aa7;
  --mdc-radio-selected-pressed-icon-color: #1c8aa7;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #1c8aa7;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: white;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #1c8aa7;
  --mdc-slider-focus-handle-color: #1c8aa7;
  --mdc-slider-hover-handle-color: #1c8aa7;
  --mdc-slider-active-track-color: #1c8aa7;
  --mdc-slider-inactive-track-color: #1c8aa7;
  --mdc-slider-with-tick-marks-inactive-container-color: #1c8aa7;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #1c8aa7;
  --mat-slider-hover-state-layer-color: rgba(28, 138, 167, 0.05);
  --mat-slider-focus-state-layer-color: rgba(28, 138, 167, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: white;
  --mat-slider-hover-state-layer-color: rgba(255, 255, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 255, 255, 0.2);
  --mdc-slider-handle-color: white;
  --mdc-slider-focus-handle-color: white;
  --mdc-slider-hover-handle-color: white;
  --mdc-slider-active-track-color: white;
  --mdc-slider-inactive-track-color: white;
  --mdc-slider-with-tick-marks-inactive-container-color: white;
  --mdc-slider-with-tick-marks-active-container-color: black;
}
html .mat-warn {
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #1c8aa7;
  --mdc-radio-selected-hover-icon-color: #1c8aa7;
  --mdc-radio-selected-icon-color: #1c8aa7;
  --mdc-radio-selected-pressed-icon-color: #1c8aa7;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: white;
  --mdc-radio-selected-hover-icon-color: white;
  --mdc-radio-selected-icon-color: white;
  --mdc-radio-selected-pressed-icon-color: white;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1c8aa7;
  --mdc-checkbox-selected-hover-icon-color: #1c8aa7;
  --mdc-checkbox-selected-icon-color: #1c8aa7;
  --mdc-checkbox-selected-pressed-icon-color: #1c8aa7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c8aa7;
  --mdc-checkbox-selected-hover-state-layer-color: #1c8aa7;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c8aa7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: white;
  --mdc-checkbox-selected-hover-state-layer-color: white;
  --mdc-checkbox-selected-pressed-state-layer-color: white;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #1c8aa7;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #1c8aa7;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #1c8aa7;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #1c8aa7;
  --mat-tab-header-active-ripple-color: #1c8aa7;
  --mat-tab-header-inactive-ripple-color: #1c8aa7;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #1c8aa7;
  --mat-tab-header-active-hover-label-text-color: #1c8aa7;
  --mat-tab-header-active-focus-indicator-color: #1c8aa7;
  --mat-tab-header-active-hover-indicator-color: #1c8aa7;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: white;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: white;
  --mat-tab-header-active-ripple-color: white;
  --mat-tab-header-inactive-ripple-color: white;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: white;
  --mat-tab-header-active-hover-label-text-color: white;
  --mat-tab-header-active-focus-indicator-color: white;
  --mat-tab-header-active-hover-indicator-color: white;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #1c8aa7;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: white;
  --mat-tab-header-with-background-foreground-color: black;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: white;
  --mdc-checkbox-selected-hover-icon-color: white;
  --mdc-checkbox-selected-icon-color: white;
  --mdc-checkbox-selected-pressed-icon-color: white;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: white;
  --mdc-checkbox-selected-hover-state-layer-color: white;
  --mdc-checkbox-selected-pressed-state-layer-color: white;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #1c8aa7;
  --mdc-checkbox-selected-hover-icon-color: #1c8aa7;
  --mdc-checkbox-selected-icon-color: #1c8aa7;
  --mdc-checkbox-selected-pressed-icon-color: #1c8aa7;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #1c8aa7;
  --mdc-checkbox-selected-hover-state-layer-color: #1c8aa7;
  --mdc-checkbox-selected-pressed-state-layer-color: #1c8aa7;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #1c8aa7;
  --mat-text-button-state-layer-color: #1c8aa7;
  --mat-text-button-ripple-color: rgba(28, 138, 167, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: white;
  --mat-text-button-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #1c8aa7;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #1c8aa7;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #1c8aa7;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #1c8aa7;
  --mat-outlined-button-ripple-color: rgba(28, 138, 167, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #1c8aa7;
  --mat-icon-button-state-layer-color: #1c8aa7;
  --mat-icon-button-ripple-color: rgba(28, 138, 167, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: white;
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #1c8aa7;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #1c8aa7;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: white;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #1c8aa7;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: white;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #1c8aa7;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: white;
  --mat-badge-text-color: black;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #1c8aa7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(28, 138, 167, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(28, 138, 167, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(28, 138, 167, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #1c8aa7;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(28, 138, 167, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: white;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 255, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 255, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: white;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #1c8aa7;
}
.mat-icon.mat-accent {
  --mat-icon-color: white;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #1c8aa7;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #1c8aa7;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #1c8aa7;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: black;
  --mat-stepper-header-selected-state-icon-background-color: white;
  --mat-stepper-header-selected-state-icon-foreground-color: black;
  --mat-stepper-header-done-state-icon-background-color: white;
  --mat-stepper-header-done-state-icon-foreground-color: black;
  --mat-stepper-header-edit-state-icon-background-color: white;
  --mat-stepper-header-edit-state-icon-foreground-color: black;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #1c8aa7;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: black;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

hr {
  border: none;
  border-top: 1px solid var(--hr-color);
}

button {
  cursor: pointer;
  --btn-height-normal: 32px;
  --btn-height-tall: 40px;
}

.btn {
  background: var(--btn-color);
  border: 0;
  border-radius: var(--control-radius);
  color: white;
  min-height: var(--btn-height-normal);
  padding: 4px 8px;
}
.btn:disabled {
  filter: grayscale(75%);
  opacity: 0.75;
}

.btn-contents {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.btn-outline {
  background: transparent;
  border: 1px solid currentColor;
  color: var(--btn-color);
}

.btn-primary {
  --btn-color: var(--primary);
}

.btn-danger {
  --btn-color: var(--danger);
}

.actions {
  display: flex;
  flex-flow: row;
  gap: 16px;
}
.actions .btn {
  font-size: 16px;
  min-height: var(--btn-height-tall);
  min-width: 150px;
}

.container {
  border-radius: var(--container-radius);
  background: white;
  padding: 16px 24px;
}

.mat-mdc-dialog-surface {
  border-radius: 12px;
  padding: 0 20px;
}

:root {
  --field-border: rgba(0, 0, 0, 0.12);
}

.force-center.mat-form-field .mat-form-field-infix {
  padding: 0.75em 0 1.25em 0;
}

.no-spacing.mat-form-field .mat-form-field-wrapper {
  padding: 0;
  margin: 0;
}

mat-form-field {
  width: 100%;
}

:root {
  --page-create-button-inset: 16px;
}

.page-create-button {
  position: fixed;
  right: var(--page-create-button-inset);
  bottom: var(--page-create-button-inset);
}
.page-create-button.mat-fab {
  position: fixed;
}

.page-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}
.page-header .back {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  height: 32px;
}
.page-header .back mat-icon {
  transform: scale(1.5);
}
.page-header h1 {
  margin-bottom: unset;
}

.relaxing-nothing-here {
  text-align: center;
  padding: 64px 0;
}
.relaxing-nothing-here img {
  margin: 20px auto;
  width: min(200px, 100%);
  opacity: 0.15;
}

.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--control-radius);
  line-height: 1.5em;
  padding: 4px 10px;
}
.status-badge[data-status-badge=good] {
  color: var(--active);
  background-color: var(--active-background);
}
.status-badge[data-status-badge=warning] {
  color: var(--warning);
  background-color: var(--warning-background);
}
.status-badge[data-status-badge=danger] {
  color: var(--disabled);
  background-color: var(--disabled-background);
}

:root {
  --mat-cell-border-color: var(--subtle-border);
}

.mat-table {
  width: 100%;
}

.mat-header-cell,
.mat-cell {
  border-bottom-color: var(--mat-cell-border-color) !important;
}

.rounded-table {
  --radius: var(--container-radius);
  border-radius: var(--radius);
}
.rounded-table thead,
.rounded-table thead tr,
.rounded-table tbody:first-child,
.rounded-table tbody:first-child tr:first-child {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}
.rounded-table thead tr th:first-child {
  border-top-left-radius: var(--radius);
}
.rounded-table thead tr th:last-child {
  border-top-right-radius: var(--radius);
}
.rounded-table thead {
  cursor: default;
}
.rounded-table tbody {
  cursor: pointer;
}
.rounded-table tr:hover {
  background-color: rgba(0, 0, 0, 0.025);
  transition: 0.25s;
}
.rounded-table thead,
.rounded-table thead tr:first-child:hover {
  background-color: unset;
}
.rounded-table tbody,
.rounded-table tbody tr:last-child {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
}
.rounded-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: var(--radius);
}
.rounded-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: var(--radius);
}
.rounded-table tbody tr:last-of-type td {
  border-bottom: unset;
}

.container.table-container {
  --pad: 12px;
  --pad-negative: -12px;
  padding: var(--pad) 0;
}
.container.table-container > .mat-table:first-child {
  margin-top: var(--pad-negative);
}
.container.table-container > .mat-table:last-child {
  margin-bottom: var(--pad-negative);
}

mat-paginator .mat-mdc-paginator-page-size {
  height: 75%;
}

.widget-column {
  text-align: center !important;
  width: 70px;
}
.widget-column:is(th) {
  text-align: center !important;
}
.widget-column:is(th) div {
  white-space: nowrap;
}
.widget-column .mat-sort-header-container {
  justify-content: center;
}
.widget-column .mat-sort-header-content {
  padding-left: 12px;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: var(--table-edge-padding, 24px) !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: var(--table-edge-padding, 24px) !important;
}

.tab-group-large .mat-tab-label {
  flex-grow: 1;
  font-size: 16px;
}
.tab-group-large .mat-tab-label-active {
  color: var(--primary);
  opacity: 1;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}