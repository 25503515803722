.relaxing-nothing-here {
  text-align: center;
  padding: 64px 0;

  img {
    margin: 20px auto;
    width: min(200px, 100%);
    opacity: 0.15;
  }
}
