@use 'define';


:root {
  @include define.color(--primary, #1c8aa7);

  // FIXME: mixing rgba with var doesn't seem to work in SCSS
  // rgba(var(--primary), 0.07);
  --sidebar-background: rgb(var(--primary-rgb) / 0.07);

  @include define.color(--active, #166534);
  --active-background: #dcfce7;

  @include define.color(--disabled, #991b1b);
  --disabled-background: rgb(var(--disabled-rgb) / 0.1);

  @include define.color(--warning, #9A3412);
  --warning-background: #FFEDD5;

  @include define.color(--danger, #d84835);

  --subtle-border: #f4f4f4;
  --row-hover: #f5f5f5;
}
