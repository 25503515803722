:root {
  --mat-cell-border-color: var(--subtle-border);
}

.mat-table {
  width: 100%;
}


.mat-header-cell,
.mat-cell {
  border-bottom-color: var(--mat-cell-border-color) !important;
}

.rounded-table {
  @mixin round($corners...) {
    @each $corner in $corners {
      border-#{$corner}-radius: var(--radius);
    }
  }

  --radius: var(--container-radius);
  border-radius: var(--radius);

  thead,
  thead tr,
  tbody:first-child,
  tbody:first-child tr:first-child {
    @include round(top-left, top-right);
  }

  thead tr th:first-child {
    @include round(top-left);
  }

  thead tr th:last-child {
    @include round(top-right);
  }

  thead {
    cursor: default;
  }

  tbody {
    cursor: pointer;
  }

  tr:hover {
    background-color: rgba(0,0,0,0.025);
    transition: 0.25s;
  }

  thead,
  thead tr:first-child:hover {
    background-color: unset;
  }

  tbody,
  tbody tr:last-child {
    @include round(bottom-left, bottom-right);
  }

  tbody tr:last-child td:first-child {
    @include round(bottom-left);
  }

  tbody tr:last-child td:last-child {
    @include round(bottom-right);
  }

  tbody tr:last-of-type td {
    border-bottom: unset;
  }
}

.container.table-container {
  --pad: 12px;
  --pad-negative: -12px;

  padding: var(--pad) 0;

  > .mat-table:first-child {
    margin-top: var(--pad-negative);
  }

  > .mat-table:last-child {
    margin-bottom: var(--pad-negative);
  }
}

mat-paginator {
  .mat-mdc-paginator-page-size {
    height: 75%;
  }
}

.widget-column {
  text-align: center !important;
  width: 70px;
  &:is(th) {
    text-align: center !important;
    div {
      white-space: nowrap;
    }
  }

  .mat-sort-header-container {
    justify-content: center;
  }

  .mat-sort-header-content  {
    padding-left: 12px; // counterbalance sort icon
  }
}

// Replace the default 24px side padding with a configurable variable
th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: var(--table-edge-padding, 24px) !important;
}

th.mat-header-cell:last-of-type,
td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: var(--table-edge-padding, 24px) !important;
}
