.status-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--control-radius);
  line-height: 1.5em;
  padding: 4px 10px;

  &[data-status-badge='good'] {
    color: var(--active);
    background-color: var(--active-background);
  }

  &[data-status-badge='warning'] {
    color: var(--warning);
    background-color: var(--warning-background);
  }

  &[data-status-badge='danger'] {
    color: var(--disabled);
    background-color: var(--disabled-background);
  }
}
