html,
body {
  background: var(--page-background);
}

html {
  font-size: 10px; // set rem
}

body {
  color: var(--text-color);
  font-family: var(--font-family-body);
  line-height: 1.33;
}
