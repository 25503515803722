button {
  cursor: pointer;
  --btn-height-normal: 32px;
  --btn-height-tall: 40px;
}

.btn {
  background: var(--btn-color);
  border: 0;
  border-radius: var(--control-radius);
  color: white;
  min-height: var(--btn-height-normal);
  padding: 4px 8px;

  &:disabled {
    filter: grayscale(75%);
    opacity: 0.75;
  }
}

.btn-contents {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.btn-outline {
  background: transparent;
  border: 1px solid currentColor;
  color: var(--btn-color);
}

.btn-primary {
  --btn-color: var(--primary);
}

.btn-danger {
  --btn-color: var(--danger);
}

.actions {
  display: flex;
  flex-flow: row;
  gap: 16px;

  .btn {
    font-size: 16px;
    min-height: var(--btn-height-tall);
    min-width: 150px;
  }
}
