.tab-group-large {
  .mat-tab-label {
    flex-grow: 1;
    font-size: 16px;
  }

  .mat-tab-label-active {
    color: var(--primary);
    opacity: 1;
  }
}
