@use 'sass:color' as sasscolor;

@mixin color($name, $color) {
  $red: sasscolor.red($color);
  $green: sasscolor.green($color);
  $blue: sasscolor.blue($color);

  #{$name}-rgb: #{$red $green $blue};
  #{$name}: rgb(var(#{$name}-rgb))
}
